import { ApiClient, apiClient } from '../../../api';

type FollowResponse = {
    is_subscribed: boolean
}

class FollowButton {
    private readonly elm: HTMLAnchorElement;
    public readonly subscribeTitle: string;
    public readonly unsubscribeTitle: string;
    private readonly url: string;
    private isSubscribed: boolean | undefined;
    private api: ApiClient;

    constructor(elm: HTMLAnchorElement, url: string) {
        this.elm = elm;
        this.url = url;

        if (!window.isAuthenticated) {
            this.elm.classList.add('disabled');
            this.elm.setAttribute('title', 'Чтобы подписаться, Вам необходимо авторизоваться.');

            return;
        }

        this.api = apiClient;

        this.elm.addEventListener('click', this.onClick);

        setTimeout(() => this.onLoad(), 200);
    }

    private showSubscribeButton(): void {
        this.elm.setAttribute('title', this.subscribeTitle);
        this.elm.classList.remove('hollow');
        this.elm.textContent = 'Подписаться';
        this.isSubscribed = false;
    }

    private showUnSubscribeButton(): void {
        this.elm.setAttribute('title', this.unsubscribeTitle);
        this.elm.classList.add('hollow');
        this.elm.textContent = '✓ Вы подписаны';
        this.isSubscribed = true;
    }

    private onLoad(): void {
        this.api.get<FollowResponse>(this.url).then(this.onSuccess, () => {});
    }

    private onClick = (e: Event): void => {
        e.preventDefault();

        if (this.isSubscribed) {
            this.api.delete<FollowResponse>(this.url).then(this.onSuccess, this.onError);
        } else {
            this.api.post<FollowResponse>(this.url).then(this.onSuccess, this.onError);
        }
    };

    private onSuccess = (response): void => {
        this.isSubscribed = response.data.is_subscribed;
        this.isSubscribed ? this.showUnSubscribeButton() : this.showSubscribeButton();
    };

    private onError = (): void => {
        alert('Произошла ошибка. Попробуйте повторить попытку позже.');
    };
}

class CompanyFollowButton extends FollowButton {
    subscribeTitle = 'Подписаться на компанию';
    unsubscribeTitle = 'Отписаться от компании';

    public static init(selectors: string = '[data-company-follow]'): void {
        document.querySelectorAll(selectors).forEach((value: HTMLAnchorElement) => {
            const companyId = value.dataset.companyFollow;

            if (companyId) {
                new CompanyFollowButton(value, `/company/${companyId}/subscribe`);
            }
        });
    }
}


class UserFollowButton extends FollowButton {
    subscribeTitle = 'Подписаться на пользователя';
    unsubscribeTitle = 'Отписаться от пользователя';

    public static init(selectors: string = '[data-user-follow]'): void {
        document.querySelectorAll(selectors).forEach((value: HTMLAnchorElement) => {
            const userId = value.dataset.userFollow;

            if (userId) {
                new UserFollowButton(value, `/user/${userId}/subscribe`);
            }
        });
    }
}

export { CompanyFollowButton, UserFollowButton };
