import $ from 'jquery';
import plural from 'plural-ru';

import './styles.scss';

type CharCounterOptions = {
    container: string;
    classname: string;
    format1: string;
    format2: string;
    format5: string;
    pulse: boolean;
    delay: number;
    limit: number;
}

const defaults: CharCounterOptions = {
    container: '<div/>',
    classname: 'charcounter',
    format1: '<div>Остался <b>%1</b> символ</div>',
    format2: '<div>Осталось <b>%1</b> символа</div>',
    format5: '<div>Осталось <b>%1</b> символов</div>',
    pulse: true,
    delay: 0,
    limit: 500
};

$.fn.charCounter = function (options?: Partial<CharCounterOptions>): void {
    const settings = { ...defaults, ...options };
    let p: null | number;
    let timeout: null | number;

    function pulse(el, again: boolean): void {
        if (p) {
            window.clearTimeout(p);
            p = null;
        }

        el.animate({ opacity: 0.1 }, 100, () => {
            el.animate({ opacity: 1.0 }, 100);
        });

        if (again) {
            p = window.setTimeout(() => {
                pulse(el, false);
            }, 200);
        }
    }

    function count(el, container: JQuery<HTMLElement>): void {
        el = $(el);
        // let max: number = el.data('char-max') || 100;

        if (el.val().length > settings.limit) {
            el.val(el.val().substring(0, settings.limit));
            if (settings.pulse && !p) {
                pulse(container, true);
            }
        }

        const chars: number = settings.limit - el.val().length;
        const format: string = plural(chars, settings.format1, settings.format2, settings.format5);
        const label: string = format.replace(/%1/, chars.toString());

        if (settings.delay > 0) {
            if (timeout) {
                window.clearTimeout(timeout);
            }
            timeout = window.setTimeout(() => {
                container.html(label);
            }, settings.delay);
        } else {
            container.html(label);
        }
    }

    this.each(function (): void {
        let container: JQuery<HTMLElement>;

        if (!settings.container.match(/^<.+>$/)) {
            // use existing element to hold counter message
            container = $(settings.container);
        } else {
            // append element to hold counter message (clean up old element first)
            $(this).next('.' + settings.classname).remove();
            container = $(settings.container).insertAfter(this).addClass(settings.classname);
        }

        $(this).off('.charCounter')
            .on('keydown.charCounter', function () {
                count(this, container);
            })
            .on('keypress.charCounter', function () {
                count(this, container);
            })
            .on('keyup.charCounter', function () {
                count(this, container);
            })
            .on('focus.charCounter', function () {
                count(this, container);
            // })
            // .on('mouseover.charCounter', function () {
            //     count(this, container);
            // })
            // .on('mouseout.charCounter', function () {
            //     count(this, container);
            // })
            // .on('paste.charCounter', function () {
            //     setTimeout(() => {
            //         count(this, container);
            //     }, 10);
            });

        if (this.addEventListener) {
            this.addEventListener('input', function () {
                count(this, container);
            }, false);
        }

        count(this, container);
    });
};

// Initialize on document ready
window.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('textarea[maxlength]').forEach(function (value: HTMLTextAreaElement) {
        if (value.dataset.redactor === undefined) {
            $(value).charCounter({ limit: value.maxLength });
        }
    });
});
