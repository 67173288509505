function convertToAnchor(elm: HTMLElement): void {
    const anchor = document.createElement('a');
    const anchorInner = document.createTextNode(elm.textContent || '');

    for (const attr in elm.dataset) {
        anchor.setAttribute(attr, <string>elm.dataset[attr]);
    }

    anchor.rel = 'nofollow noopener';
    anchor.append(anchorInner);

    if (elm.parentNode) {
        elm.parentNode.replaceChild(anchor, elm);
    }
}

export { convertToAnchor };
