import { AxiosError } from 'axios';
import { ApiClient, apiClient } from '../../../api';

type FavoritesResponse = {
    is_exists: boolean
}

class FavoritesStatic {
    private api: ApiClient;
    private readonly url: string;
    private isExists: boolean;

    private rootElement: HTMLAnchorElement;
    private readonly textElement: HTMLSpanElement | null;

    constructor(elm: HTMLAnchorElement, url: string) {
        this.api = apiClient;
        this.url = url;

        this.rootElement = elm;
        this.rootElement.addEventListener('click', this.onClick);
        this.textElement = this.rootElement.querySelector('.text-favorites');

        setTimeout(() => this.onLoad(), 200);
    }

    public static init(selector: string = '[data-favorites]'): void {
        document.querySelectorAll(selector).forEach(function (value: HTMLAnchorElement) {
            const appLabel = value.dataset.favoritesAppLabel;
            const model = value.dataset.favoritesModel;
            const objectId = value.dataset.favoritesObjectId;

            if (appLabel && model && objectId) {
                new FavoritesStatic(value, `/${appLabel}/${model}/${objectId}/favorite`);
            }
        });
    }

    private showAddFavorite(): void {
        this.isExists = false;
        this.rootElement.classList.remove('is-exists');
        this.rootElement.setAttribute('title', 'Добавить в избранное');

        if (this.textElement) {
            this.textElement.textContent = 'В избранное';
        }
    }

    private showDeleteFavorite(): void {
        this.isExists = true;
        this.rootElement.classList.add('is-exists');
        this.rootElement.setAttribute('title', 'Удалить из избранного');

        if (this.textElement) {
            this.textElement.textContent = 'В избранном';
        }
    }

    private onLoad(): void {
        this.api.get<FavoritesResponse>(this.url).then(this.onSuccess, () => {});
    }

    private onClick = (e): void => {
        e.preventDefault();

        if (this.isExists) {
            this.api.delete<FavoritesResponse>(this.url).then(this.onSuccess, this.onError);
        } else {
            this.api.post<FavoritesResponse>(this.url).then(this.onSuccess, this.onError);
        }
    };

    private onSuccess = (response): void => {
        this.isExists = response.data.is_exists;
        this.isExists ? this.showDeleteFavorite() : this.showAddFavorite();
    };

    private onError = (_: AxiosError): void => {
        alert('Произошла ошибка. Попробуйте повторить попытку позже.');

        // throw new Error(error.message);
    };
}

type Favorites = FavoritesStatic;
const Favorites = FavoritesStatic as typeof FavoritesStatic & (() => Favorites);

export { Favorites };
