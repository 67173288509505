import axios from 'axios';
import throttle from 'lodash/throttle';

const BS_AJAX_URL = (PRODUCTION ? 'https://bs.elec.ru' : 'http://bs.elec.local:8000') + '/bs/place.php';
const BS_IFRAME_URL = (PRODUCTION ? 'https://bs.elec.ru' : 'http://bs.elec.local:8000') + '/bs/iframe.php';

enum AdvertType {
    Ajax,
    IFrame,
    Topline
}

class AdvertStatic {
    private readonly elm: HTMLElement;
    private readonly elmRect: DOMRect;
    private readonly elmType: AdvertType;
    private readonly elmWidth: string;
    private readonly elmHeight: string;
    private readonly elmClass: string | undefined;

    private readonly bsId: string;
    private readonly bsPrimary: string | undefined;
    private readonly bsSecondary: string | undefined;

    private isLoading: boolean;

    constructor(elm: HTMLElement) {
        if (!(this instanceof AdvertStatic)) {
            return new AdvertStatic(elm);
        }

        this.elm = elm;
        this.elmRect = elm.getBoundingClientRect();
        this.elmWidth = elm.dataset.bsWidth ?? '100%';
        this.elmHeight = elm.dataset.bsHeight ?? '90';
        this.elmClass = elm.dataset.bsClass;

        switch (elm.dataset?.bsType) {
        case 'ajax':
            this.elmType = AdvertType.Ajax;
            break;
        case 'topline':
            this.elmType = AdvertType.Topline;
            break;
        default:
            this.elmType = AdvertType.IFrame;
        }

        this.bsId = elm.dataset.bs ?? '0';
        this.bsPrimary = elm.dataset.bsPrimary ?? '0';
        this.bsSecondary = elm.dataset.bsSecondary ?? '0';
        this.isLoading = false;

        // TODO Refresh on visibilitychange event
        if (this.isVisible()) {
            this.load().finally(() => {
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        } else {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    private handleScroll = throttle((): void => {
        if (this.isVisible()) {
            this.load().finally(() => {
                window.removeEventListener('scroll', this.handleScroll);

                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            });
        }
    }, 100);

    private isVisible(): boolean {
        if (!this.elm.offsetParent) {
            return false;
        }
        return this.elmRect.bottom < window.scrollY + window.innerHeight;
    }

    private async load(): Promise<void> {
        if (this.isLoading) {
            return Promise.reject();
        }

        this.isLoading = true;

        return this.elmType == AdvertType.IFrame ? this.loadIFrame() : this.loadAjax();
    }

    private async loadAjax(): Promise<void> {
        const result = await axios.get(`${BS_AJAX_URL}`, {
            headers: {
                Accept: 'text/html'
            },
            params: this.urlParams(),
            withCredentials: true
        });

        if (result.data) {
            this.elm.innerHTML = result.data;
            if (this.elmClass) {
                this.elm.setAttribute('class', this.elmClass);
            }
        } else {
            this.elm.remove();
        }

        return Promise.resolve();
    }

    private async loadIFrame(): Promise<void> {
        const iframe = document.createElement('iframe');

        iframe.setAttribute('class', 'banner');
        iframe.setAttribute('width', this.elmWidth);
        iframe.setAttribute('height', this.elmHeight);
        iframe.setAttribute('src', encodeURI(`${BS_IFRAME_URL}?${this.urlParams().toString()}`));

        this.elm.appendChild(iframe);
        if (this.elmClass) {
            this.elm.setAttribute('class', this.elmClass);
        }

        return Promise.resolve();
    }

    private urlParams(): URLSearchParams {
        const params = new URLSearchParams([['id', this.bsId]]);

        if (this.bsPrimary) {
            params.append('bsPrimary', this.bsPrimary);
        }

        if (this.bsSecondary) {
            params.append('bsSecondary', this.bsSecondary);
        }

        params.append('rnd', Math.random().toString());

        return params;
    }

    static init(selectors: string): void {
        document.querySelectorAll(selectors).forEach((value: HTMLElement) => {
            return new AdvertStatic(value);
        });
    }
}

type AdvertInstance = AdvertStatic;
const Advert = AdvertStatic as typeof AdvertStatic & ((elm: HTMLElement) => AdvertInstance);

export { Advert };
